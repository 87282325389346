import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { Share } from "./services/ShareService/types"
import { pathList } from "./routes/pathList"
import { Instance } from "./components/DicomViewer/types"
import { CornerstoneImages } from "./components/DicomViewer/Cornerstone/types"
dayjs.extend(customParseFormat)

export function formatFileSize(size: number) {
  const prefixes = ["", "k", "m", "g", "t", "p"]
  for (const prefix of prefixes) {
    if (size < 1000) {
      return `${size.toFixed(1)}${prefix?.toUpperCase()}B`
    }
    size /= 1000
  }
  return "Very big!"
}

export function htmlEntities(str: string) {
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
}

export function isInThePast(date: Date) {
  const today = new Date()

  // 👇️ OPTIONAL!
  // This line sets the hour of the current date to midnight
  // so the comparison only returns `true` if the passed-in date
  // is at least yesterday
  today.setHours(0, 0, 0, 0)

  return date < today
}

export function isInTheFuture(date: Date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return date > today
}

export const getParsedDate = (date: string) => {
  return dayjs(date, getParsedDate.formats, true)
}
getParsedDate.formats = ["MMDDYYYY", "MM/DD/YYYY", "YYYY-MM-DD"]
getParsedDate.defaultFormat = getParsedDate.formats[1]

export function validateDateInput(parsedDate: Dayjs) {
  return parsedDate.isValid()
}

export const convertDateFormat = (date: string) => {
  const parsedDate = getParsedDate(date)
  const dateFormat = "YYYY-MM-DD" // Define the date format as a constant

  if (validateDateInput(parsedDate)) {
    return parsedDate.format(dateFormat)
  }
  // Invalid input, return an empty string or handle the error as needed
  return ""
}

export const formatShareURL = (code: Share["code"]) => {
  return pathList.lookupCode.reverse({ code }, true)
}

export const isSingleImageAndStack = (instance: Instance) => {
  return (
    instance.frames === 1 &&
    !Object.values<typeof instance.modality>(CornerstoneImages).includes(
      instance.modality
    )
  )
}
