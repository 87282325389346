export function getAndStripAuthHeadersFromURL(
  rawURL: string,
  currentHeaders: unknown
) {
  // Get the final URL and headers for the fetch() call for this thumbnail
  // fetch() doesn't support in-url Basic Auth, so we strip that
  //  out and add it to the headers (if applicable)
  const urlObj = new URL(rawURL)
  let url = rawURL

  const headers = JSON.parse(JSON.stringify(currentHeaders))
  if (urlObj.username !== "") {
    const headerStr = urlObj.username + ":" + urlObj.password
    headers["Authorization"] = "Basic " + btoa(headerStr)
    url = url.replace(headerStr + "@", "")
  }

  return {
    headers: headers,
    url: url,
  }
}

export async function fetchWithRetries(
  url: RequestInfo | URL,
  headers: RequestInit,
  maxRetries: number,
  interval: number
): Promise<Response> {
  let response = new Response()

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    response = await fetch(url, headers)

    if (response.status === 200) {
      break
    }
    await new Promise((resolve) => setTimeout(resolve, interval * attempt))
  }
  return response
}
