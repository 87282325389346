import React from "react"
import logoSvg from "../../assets/imgs/logo.svg"
import "./header.scss"
import { Image, Row } from "react-bootstrap"
import { pathList } from "../../routes/pathList"
import signOut from "./assets/imgs/export.png"
import { useUser } from "../../hooks"
import { Link, useParams } from "react-router-dom"
import { formatShareURL } from "../../utils"

const PatientHeader = () => {
  const user = useUser()
  const { code } = useParams()

  return (
    <Row fluid="true">
      <div className="header-color">
        <header className="d-flex align-items-center p-1">
          <div className="logo d-flex align-items-center">
            <img src={logoSvg} alt="logo" className="ms-3 me-3" />{" "}
            <div className="mt-3 font-size d-none d-md-block">
              <span className="dark-color fw-bold">MedIQ </span>
              <span className="text-heading-color">
                Patient Information Management System
              </span>
            </div>
          </div>
          {!user && (
            <div className="text-danger fw-medium p-2 font-size mb-md-2 ms-auto">
              <Link to={code ? formatShareURL(code) : pathList.root.path}>
                <Image alt="signout" src={signOut} /> Sign Out
              </Link>
            </div>
          )}
        </header>
      </div>
    </Row>
  )
}

export default PatientHeader
