import React from "react"
import { formatFileSize, htmlEntities } from "../../utils"

export interface FileListProps {
  files: File[]
}

export const UploadFileList = React.memo(
  ({ files }: React.PropsWithChildren<FileListProps>) => {
    if (!(files && files.length)) {
      return <></>
    }

    function c(name: string) {
      return `file-list-${name}`
    }

    return (
      <ul>
        {files.map((file: File) => (
          <li key={`${file.name}_${file.lastModified}`}>
            <span className={c("name")}>{htmlEntities(file.name)}</span>{" "}
            <span className={c("size")}>({formatFileSize(file.size)})</span>
          </li>
        ))}
      </ul>
    )
  }
)

UploadFileList.displayName = "FileList"
