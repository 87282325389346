import { path } from "./path"

export const pathList = {
  root: path("/"),
  lookup: path("/s"),
  lookupCode: path("/s/:code"),
  lookupDetails: path("/s/:code/detail"),
  view: path("/s/:code/view"),

  login: path("/login"),

  // auth required
  list: path("/list"),
  create: path("/new"),
  edit: path("/:id"),
}

export type PathListKeys = keyof typeof pathList
