import * as Sentry from "@sentry/react"
import React from "react"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

export const createRouter: typeof createBrowserRouter = (...args) => {
  const sentry_dsn = process.env.SENTRY_DSN
  if (!sentry_dsn) {
    return createBrowserRouter(...args)
  }

  Sentry.init({
    dsn: sentry_dsn,
    environment: process.env.SENTRY_ENV ?? "production",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
  return Sentry.wrapCreateBrowserRouter(createBrowserRouter)(...args)
}
