import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback } from "react"
import { Key } from "ts-key-enum"
import { useDispatch } from "./context"
import { PlaybackState } from "./context/types"
import { useKeyboardHandler } from "./keyboard"
import { ToolbarButton } from "./ToolbarButton"
import { Button } from "react-bootstrap"

export const CinePlayButton = () => {
  const { state, setPlaybackStateAll, setPlaybackState } = useDispatch()

  const playbackInfo = React.useMemo(
    () => state.panels.map((panelState) => panelState.playbackInfo),
    [state]
  )

  const setPlaybackStateSelected = React.useCallback(
    (state: PlaybackState) => setPlaybackState({ index: -1, state }),
    [setPlaybackState]
  )

  const spacePressed = useCallback(() => {
    const isPlaying = playbackInfo.some((info) => info.state === "play")
    setPlaybackStateAll(isPlaying ? "stop" : "play")
  }, [playbackInfo, setPlaybackStateAll])
  const arrowUpPressed = useCallback(() => {
    setPlaybackStateAll("prev")
  }, [setPlaybackStateAll])
  const arrowDownPressed = useCallback(() => {
    setPlaybackStateAll("next")
  }, [setPlaybackStateAll])

  useKeyboardHandler(" ", spacePressed)
  useKeyboardHandler(Key.ArrowUp, arrowUpPressed)
  useKeyboardHandler(Key.ArrowDown, arrowDownPressed)

  return (
    <ToolbarButton title="Cine" icon="none">
      <Button
        variant="secondary"
        className={"m-1"}
        onClick={() => setPlaybackStateSelected("first")}
      >
        <FontAwesomeIcon icon="fast-backward" />
      </Button>
      <Button
        variant="secondary"
        className={"btn btn-icon btn-light-success btn-sm btn-circle m-1"}
        onClick={() => setPlaybackStateSelected("prev")}
      >
        <FontAwesomeIcon icon="backward" />
      </Button>
      <Button
        variant="secondary"
        className={"btn btn-icon btn-light-success btn-sm btn-circle m-1"}
        onClick={() => setPlaybackStateSelected("play")}
      >
        <FontAwesomeIcon icon="play" />
      </Button>
      <Button
        variant="secondary"
        className={"btn btn-icon btn-light-success btn-sm btn-circle m-1"}
        onClick={() => setPlaybackStateSelected("stop")}
      >
        <FontAwesomeIcon icon="pause" />
      </Button>
      <Button
        variant="secondary"
        className={"btn btn-icon btn-light-success btn-sm btn-circle m-1"}
        onClick={() => setPlaybackStateSelected("next")}
      >
        <FontAwesomeIcon icon="forward" />
      </Button>
      <Button
        variant="secondary"
        className={"btn btn-icon btn-light-success btn-sm btn-circle m-1"}
        onClick={() => setPlaybackStateSelected("last")}
      >
        <FontAwesomeIcon icon="fast-forward" />
      </Button>
    </ToolbarButton>
  )
}
