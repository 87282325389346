import { fetchWithRetries, getAndStripAuthHeadersFromURL } from "./utils"

const MAX_RETRIES = 3
const RETRIES_INTERVAL = 500 // 500 ms
export interface BlobCacheElement {
  url: string
  blob: Blob
}

interface WaitingElement {
  url: string
  p: Promise<BlobCacheElement>
}

export const cache = (function () {
  const members: BlobCacheElement[] = []
  const waiting: WaitingElement[] = []

  async function nakedGet(inUrl: string): Promise<BlobCacheElement> {
    const { url, headers } = getAndStripAuthHeadersFromURL(inUrl, {
      Accept: "image/jpeg",
    })
    const response = await fetchWithRetries(
      url,
      { headers: headers },
      MAX_RETRIES,
      RETRIES_INTERVAL
    )
    const blob = await response.blob()
    // 	  'url' has potentially had part stripped
    return { url: inUrl, blob }
  }

  async function getAsync(url: string): Promise<BlobCacheElement> {
    // check if completed
    const cacheIndex = members.findIndex((c) => c.url === url)
    if (cacheIndex !== -1) return members[cacheIndex]

    // check if already requested
    const waitingIndex = waiting.findIndex((c) => c.url === url)
    if (waitingIndex !== -1) return await waiting[waitingIndex].p

    // not requested; add to waiting list
    const p = nakedGet(url)
    waiting.push({ url, p })

    // wait for completion
    const item = await p

    // add to completed and remove from waiting
    if (members.findIndex((c) => c.url === url) === -1) members.push(item)
    waiting.filter((m) => m.url !== url)
    // console.log(members.map((m) => m.url));
    return item
  }

  function contains(url: string) {
    const cacheIndex = members.findIndex((c) => c.url === url)
    return cacheIndex !== -1
  }

  function getSync(url: string) {
    const cacheIndex = members.findIndex((c) => c.url === url)
    if (cacheIndex === -1) return null
    return members[cacheIndex]
  }

  return {
    getAsync,
    contains,
    getSync,
  }
})()
