import { Log } from "./logger"
import { Instance, Point } from "./types"

export interface UltrasoundRegion {
  min: Point
  max: Point
  deltaX: number // normalized to cm
  deltaY: number // normalized to cm
}

export interface ParsedDicomMetadata {
  cineRate: number
  regions: null | UltrasoundRegion[]
}

// types below are created to pass linting; may not fully represent the specs

type DicomPrimitiveValue = string | number | DicomPrimitiveValue[]

interface DicomElement {
  Value: DicomPrimitiveValue | DicomElement | DicomValues[]
}

interface DicomValues {
  [name: string]: DicomElement | DicomValues
}

type DicomMetadata = DicomValues[]

const CINE_RATE = "00180040"
const FRAME_RATE = "00082144"
const FRAME_TIME = "00181063"
const ULTRASOUND_REGIONS = "00186011"
const REGION_LOCATION_MIN_X0 = "00186018"
const REGION_LOCATION_MIN_Y0 = "0018601A"
const REGION_LOCATION_MAX_X1 = "0018601C"
const REGION_LOCATION_MAX_Y1 = "0018601E"
const PHYSICAL_DELTA_X = "0018602C"
const PHYSICAL_DELTA_Y = "0018602E"
const PHYSICAL_UNITS_X_DIRECTION = "00186024"
const PHYSICAL_UNITS_Y_DIRECTION = "00186026"
const INSTANCE_UID = "00080018"

export function parseDicomMetadata(
  metadata: DicomMetadata
): ParsedDicomMetadata {
  function getValue(tag: string, missingVal?: DicomPrimitiveValue) {
    return metadata[0][tag] !== undefined
      ? (metadata[0][tag].Value as DicomPrimitiveValue[])[0]
      : missingVal
  }

  const instanceId = getValue(INSTANCE_UID) as Instance["instanceId"]

  function parseRegionUnits(value: DicomPrimitiveValue) {
    if (typeof value === "number") return value
    if (Array.isArray(value)) {
      const isNumber = typeof value[0] === "number"
      if (value.length === 1 && isNumber) return value[0]
      const allTheSame = value.every((v) => v === value[0])
      if (allTheSame && isNumber) return value[0]
    }
    return undefined
  }

  function parseRegion(region: DicomValues): UltrasoundRegion | null {
    const deltaX = region[PHYSICAL_DELTA_X].Value
    const deltaY = region[PHYSICAL_DELTA_Y].Value
    const xUnits = parseRegionUnits(
      region[PHYSICAL_UNITS_X_DIRECTION].Value as DicomPrimitiveValue
    )
    const yUnits = parseRegionUnits(
      region[PHYSICAL_UNITS_Y_DIRECTION].Value as DicomPrimitiveValue
    )
    const unitsOk = xUnits === 3 && yUnits === 3 // cm

    if (xUnits !== 3) {
      Log.warn(
        "Unknown x-distance units.",
        region[PHYSICAL_UNITS_X_DIRECTION].Value,
        instanceId
      )
    }
    if (yUnits !== 3) {
      Log.warn(
        "Unknown y-distance units.",
        region[PHYSICAL_UNITS_Y_DIRECTION].Value,
        instanceId
      )
    }
    if (!unitsOk) return null

    return {
      min: {
        x: region[REGION_LOCATION_MIN_X0].Value as number,
        y: region[REGION_LOCATION_MIN_Y0].Value as number,
      },
      max: {
        x: region[REGION_LOCATION_MAX_X1].Value as number,
        y: region[REGION_LOCATION_MAX_Y1].Value as number,
      },
      deltaX: deltaX as number,
      deltaY: deltaY as number,
    }
  }

  const dicomRegions =
    metadata[0][ULTRASOUND_REGIONS] !== undefined
      ? (metadata[0][ULTRASOUND_REGIONS].Value as DicomValues[])
      : undefined
  const regions =
    dicomRegions === undefined
      ? null
      : (dicomRegions.map(parseRegion).filter((r) => !!r) as UltrasoundRegion[])
  // TODO: fix this to use the FRAME_INCREMENT_POINTER field to
  // to calculate FPS
  const cineRateResult = getValue(CINE_RATE, 0)
  const frameRateResult = getValue(FRAME_RATE, 0)
  const frameTimeResult = getValue(FRAME_TIME, 0)
  const cineRate =
    cineRateResult ||
    frameRateResult ||
    (frameTimeResult !== 0 ? 1000 / parseFloat(frameTimeResult as string) : 30)
  return {
    cineRate: cineRate as number,
    regions,
    // imageSize: imageSize,
    // imageDpi: imageDpi
  }
}
