import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, Form, Alert } from "react-bootstrap"
import logoSvg from "../../assets/imgs/logo.svg"
import artWork from "../../assets/Artwork.png"
import "./patientLogin.module.scss"
import Input from "../../components/Input/input"
import Button from "../../components/Button"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import s from "../patientLogin/patientLogin.module.scss"
import Slider from "../../components/Slider/slider"
import { LoaderFunctionArgs } from "@remix-run/router/dist/utils"
import shareService from "../../services/ShareService"
import { ApiError } from "../../services/ApiService"
import Lookup from "../lookup"
import { pathList } from "../../routes/pathList"
import { slides } from "../landing/slides"
import ShareLink from "../../components/ShareLink"
import {
  convertDateFormat,
  getParsedDate,
  validateDateInput,
  isInTheFuture,
} from "../../utils"
import { getUnitDetails } from "../../services/ShareService/ShareService"
import { PublicShareUnit } from "../../services/ShareService/types"
import { PublicShare } from "../../services/ShareService/types"
import { Study } from "../../components/DicomViewer/types"

function NotFound({
  notFound,
  errorMessage,
}: {
  notFound?: boolean
  errorMessage?: string
}) {
  if (!notFound) {
    return <></>
  }
  return (
    <Container>
      <Row>
        <Alert variant="danger">{errorMessage}</Alert>
      </Row>
    </Container>
  )
}

enum RequestStatus {
  Pending = "pending",
  InProgress = "inProgress",
  Completed = "completed",
}

const PatientLogin = () => {
  const { code } = useParams()
  const [notFound, setNotFound] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>("")
  const [dobError, setDobError] = useState<string>("")
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.Pending
  )
  const [shareUnit, setShareUnit] = useState<PublicShareUnit>()

  const navigate = useNavigate()
  const fetchShareDetails = async (code: string, dob: string) => {
    const share = await shareService.lookup(code, convertDateFormat(dob))

    if (share instanceof ApiError) {
      if (share.notFound) {
        setNotFound(true)
        setErrorMessage(share.errorMessage)
      }
      return
    }
    return share
  }

  const fetchStudies = async (code: string) => {
    const studies = await shareService.studies(code)
    if (studies instanceof ApiError) {
      return
    }
    return studies
  }

  const navigateToDetailsPage = (
    share: PublicShare,
    studies: Study[],
    navigate: NavigateFunction,
    code: string
  ) => {
    navigate(pathList.lookupDetails.reverse({ code }), {
      state: { share, studies },
    })
  }
  const [showBranding, setShowBranding] = useState<boolean>(false)

  useEffect(() => {
    const fetchUnitDetails = async () => {
      if (!code) {
        setShowBranding(false)
        return
      }
      const result = await getUnitDetails(code)
      if (result instanceof ApiError || !result) {
        setShowBranding(false)
      } else {
        setShareUnit(result)
      }
    }

    fetchUnitDetails()
  }, [code])

  useEffect(() => {
    setShowBranding(true)
  }, [code])

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setRequestStatus(RequestStatus.InProgress)

      const form = e.currentTarget
      e.preventDefault()
      const share = await fetchShareDetails(form.code.value, form.dob.value)
      const studies = await fetchStudies(form.code.value)
      if (share && studies) {
        navigateToDetailsPage(share, studies, navigate, form.code.value)
        setRequestStatus(RequestStatus.Completed)
      }
      setRequestStatus(RequestStatus.Completed)
    },
    [navigate]
  )

  const onDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget
    const parsedInput = getParsedDate(input.value)
    const isValid = validateDateInput(parsedInput)
    let errorMessage = ""

    if (!isValid) {
      const formats = getParsedDate.formats.join(", ")
      errorMessage = `Invalid date format. Accepted formats: ${formats}`
    } else if (isInTheFuture(parsedInput.toDate())) {
      errorMessage = "Date of birth cannot be in the future."
    }

    setDobError(errorMessage)
    input.setCustomValidity(errorMessage)
  }

  return (
    <Container
      fluid
      className={`position-relative ${s.patientLoginBackground}`}
    >
      <Row className="d-flex flex-column-mbl min-vh-100 justify-content-center">
        <Col
          md={5}
          xl={4}
          sm={12}
          className="z-1 d-flex align-items-center justify-content-center"
        >
          <Card className={`m-md-5 m-sm-3 ${s.cardContainer}`}>
            <Card.Body className="login-wrapper p-4 pt-4 pd-4">
              <div className="d-flex flex-column">
                {showBranding ? (
                  <div className="logo d-inline-block  mb-4">
                    {shareUnit?.unit.logo ? (
                      <>
                        <img
                          src={shareUnit.unit.logo}
                          alt="logo"
                          className="me-3"
                        />
                        <span className="dark-color">
                          {shareUnit.unit.name}
                        </span>
                      </>
                    ) : (
                      <span className="dark-color">
                        {shareUnit?.unit?.name}
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="logo d-inline-block">
                      <img src={logoSvg} alt="Sarc MedIQ" />
                    </div>
                    <p className="grey-color logo-cap mt-2">
                      Information Management System
                    </p>
                  </div>
                )}
              </div>

              <div className="second-interface">
                <Form
                  method="post"
                  onSubmit={onSubmit}
                  rel="noreferrer"
                  className="pt-2"
                >
                  <div className="mb-2">
                    <Input
                      type="text"
                      name="code"
                      placeholder="Enter Code"
                      label="Code"
                      isRequired={true}
                      defaultValue={code || ""}
                      onChange={() => setShowBranding(false)} // Hide branding when code changes
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      type="text"
                      name="dob"
                      placeholder="MM/DD/YYYY"
                      label="Patient date of birth"
                      className="date-input"
                      isRequired={true}
                      onChange={onDobChange}
                      aria-invalid={!!dobError}
                      aria-describedby="dobError"
                    />
                  </div>
                  <NotFound notFound={notFound} errorMessage={errorMessage} />
                  <Button
                    className="w-100 d-flex align-items-center dark-bg"
                    label={`${
                      requestStatus === RequestStatus.Completed ||
                      requestStatus === RequestStatus.Pending
                        ? "Access My Images"
                        : ""
                    }`}
                    buttonType={"submit"}
                    name={"patient_login"}
                    centerLabel={true}
                    showSpinner={requestStatus === RequestStatus.InProgress}
                  />
                </Form>
                <div className="mt-1 mb-1 text-center">
                  <div className="d-flex align-center justify-content-between mt-3">
                    <p className="grey-color mb-0">Having trouble?</p>
                    <a
                      href={
                        shareUnit?.unit?.email
                          ? `mailto:${shareUnit.unit.email}`
                          : "mailto:support@sarcmediq.com"
                      }
                      className="cursor-pointer mb-0"
                    >
                      Contact Us
                    </a>
                  </div>
                  {showBranding && code ? (
                    <hr />
                  ) : (
                    !showBranding && (
                      <img className="w-75 mt-2" src={artWork} alt="artwork" />
                    )
                  )}
                </div>
                {showBranding && code && (
                  <ShareLink link={window.location.href} />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col
          md={7}
          xl={8}
          sm={0}
          className={`align-content-center position-relative overflow-hidden ${s.backgroundImage} d-none d-md-block`}
        >
          <div className="login-wrapper">
            <Slider slides={slides} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

Lookup.loader = async ({ params }: LoaderFunctionArgs) => {
  return { code: params.code }
}

export default PatientLogin
