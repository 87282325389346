import { RouteObject } from "react-router-dom"
import OnboardingLayout from "../components/OnboardingLayout/OnboardingLayout"
import Landing from "../pages/landing/landing"
import AdminLogin from "../pages/adminLogin/login"
import { pathList } from "./pathList"

const routes: RouteObject[] = [
  {
    path: pathList.root.path,
    element: <OnboardingLayout />,
    children: [
      {
        path: pathList.root.path,
        element: <Landing />,
      },
      {
        path: pathList.login.path,
        element: <AdminLogin />,
        action: AdminLogin,
      },
    ],
  },
]

export default routes
