import React, { useRef, useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import PatientHeader from "../../components/PatientHeader/header"
import PatientFilesListing from "../../components/PatientFilesListing/patientFiles"
import PatientProfileInfo from "../../components/PatientProfile/patientProfile"
import {
  useLocation,
  useNavigate,
  useParams,
  useLoaderData,
} from "react-router-dom"
import { pathList } from "../../routes/pathList"
import shareService from "../../services/ShareService"
import { LoaderFunctionArgs } from "react-router-dom"
import { ApiError } from "../../services/ApiService"
import { ShareFile } from "../../services/ShareService/types"
import s from "./patientHome.module.scss"
import { useMediaQuery } from "react-responsive"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface LoaderData {
  files: ShareFile[]
  errorMessage?: string
}

const PatientHome = () => {
  const { files } = useLoaderData() as LoaderData
  const location = useLocation()
  const navigate = useNavigate()
  const { code } = useParams()
  const { share = {}, studies = [] } = location?.state ?? {}
  const filesCount = (share.files?.length ?? 0) + (studies.length ?? 0)
  const [isAtTop, setIsAtTop] = useState(true)
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" })
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!location.state && code) {
      navigate(pathList.lookupCode.reverse({ code }))
    }
  }, [location, navigate, code])

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  if (!location.state) {
    return null
  }

  const handleScrollDown = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Container fluid className="light-bg">
      <PatientHeader />
      <div className="light-bg">
        <Row className={`${s.details} mt-4 mt-md-0`}>
          <Col md={4}>
            <div
              className={`mt-md-4 mb-md-4 p-2 p-md-0 fw-bold dark-color ${s.detailwrapper}`}
            >
              <span className="fw-bold h5 p-3">Patient Details</span>
            </div>
            <PatientProfileInfo isSmallScreen={isSmallScreen} />
          </Col>
          <Col md={8}>
            <div className="mt-4 mb-4 dark-color" ref={ref}>
              <span className="fw-bold h5 p-3">Your Image Library</span>
              <span className="text-muted fw-normal">({filesCount})</span>
            </div>
            <PatientFilesListing
              shareFiles={files}
              isSmallScreen={isSmallScreen}
            />
          </Col>
        </Row>
      </div>
      {isSmallScreen && isAtTop ? (
        <div className={s.scrollDownArrow}>
          <FontAwesomeIcon icon="chevron-down" onClick={handleScrollDown} />
        </div>
      ) : null}
    </Container>
  )
}
PatientHome.loader = async ({ params }: LoaderFunctionArgs) => {
  const code = params.code as string
  try {
    const files = await shareService.filesByCode(code)
    if (files instanceof ApiError) {
      return { errorMessage: files.errorMessage }
    }
    return { files }
  } catch (error) {
    return { errorMessage: (error as Error).message }
  }
}

export default PatientHome
