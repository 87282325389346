import React from "react"
import { useDispatch } from "./context"
import { ImagePanel } from "./ImagePanel"
import s from "./ImageViewArea.module.scss"

interface ImageViewAreaProps {
  isDefaultToolbar: boolean
  viewer: React.RefObject<HTMLElement>
}

export const ImageViewArea = ({
  isDefaultToolbar,
  viewer,
}: ImageViewAreaProps) => {
  const { state } = useDispatch()
  const { rows, cols } = state.layout

  const rowArr = [...Array(rows).keys()]
  const colArr = [...Array(cols).keys()]
  const rowHeight = (100 / rows).toString() + "%"

  return (
    <div className={isDefaultToolbar ? s.imageBlock : s.imageViewArea}>
      {rowArr.map((row) => (
        <div
          key={"display-row-" + row}
          style={{
            height: rowHeight,
            width: "100%",
          }}
        >
          {colArr.map((col) => (
            <ImagePanel
              index={row * cols + col}
              key={"panel-" + row * cols + col}
              viewer={viewer}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
