import { produce } from "immer"
import React from "react"
import actions, { Actions, ReducerActions } from "./actions"
import { State } from "./types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ActionPayload<P = any> {
  type: keyof typeof Actions
  payload: P
}

interface CreateReducerParams {
  actions: ReducerActions
}

export const createReducer =
  (params: CreateReducerParams): React.Reducer<State, ActionPayload> =>
  (state, action): State => {
    const actionHandler = params.actions[action.type]
    if (actionHandler) {
      return produce((draft) => {
        actionHandler(draft, action)
      })(state)
    }
    return state
  }

export const mainReducer = createReducer({ actions })
