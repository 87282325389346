import apiService, { ApiError } from "./ApiService"
import { User } from "./AuthService"

class UserService {
  private localStorageKey = "user"

  public get user(): User | undefined {
    const userString = localStorage.getItem(this.localStorageKey)
    if (userString) {
      return JSON.parse(userString)
    }
    return undefined
  }

  public set user(user: User | undefined) {
    if (!user) {
      localStorage.removeItem(this.localStorageKey)
    } else {
      localStorage.setItem(this.localStorageKey, JSON.stringify(user))
    }
  }

  async loadProfile(): Promise<User | ApiError> {
    return await apiService.request<User>({ endpoint: "/user/" })
  }
}

const userService = new UserService()
export default userService
