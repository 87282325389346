import React from "react"
import ErrorPage from "../pages/error"
import Lookup from "../pages/lookup"
import { createRouter } from "./createRouter"
import { pathList } from "./pathList"
import PatientLogin from "../pages/patientLogin/patientLogin"
import protectedRoutes from "./protectedRoutes"
import PatientHome from "../pages/patientDetail/patientHome"
import Root from "../pages/root"
import onboardingRoutes from "./onboardingRoutes"

export default createRouter([
  ...onboardingRoutes,
  {
    path: pathList.lookup.path,
    element: <PatientLogin />,
    loader: Lookup.loader,
    action: Lookup.action,
  },
  {
    path: pathList.lookupCode.path,
    element: <PatientLogin />,
    loader: Lookup.loader,
    action: Lookup.action,
  },
  {
    path: pathList.root.path,
    element: <Root />,
    children: protectedRoutes,
  },
  {
    path: pathList.lookupDetails.path,
    element: <PatientHome />,
    loader: PatientHome.loader,
    action: PatientHome,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
])
