import React from "react"
import s from "./ViewerToolbar.module.scss"
import { CinePlayButton } from "./CinePlayButton"
import { HelpButton } from "./HelpButton"
import { LayoutButton } from "./LayoutButton"

interface ViewerToolbarProps {
  viewer: React.RefObject<HTMLElement>
  imagesLength: number
}

const ViewerToolbar: React.FC<ViewerToolbarProps> = ({
  viewer,
  imagesLength,
}) => {
  return (
    <>
      <div className={s.topRightToolbar}>
        <HelpButton />
        <LayoutButton viewer={viewer} />
      </div>
      {imagesLength > 1 && (
        <div className={s.bottomCenterToolbar}>
          <CinePlayButton />
        </div>
      )}
    </>
  )
}

export default ViewerToolbar
