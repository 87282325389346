import React from "react"
import { Displayable, ShareFile } from "../../../services/ShareService/types"
import { Instance } from "../types"
import { Actions } from "./actions"
import { ViewContext } from "./context"
import { PlaybackControl, PlaybackState } from "./types"

export const useDispatch = () => {
  const { state, getImageStack, dispatch } = React.useContext(ViewContext)
  return {
    state,
    getImageStack,
    setObject: React.useCallback(
      (object: Displayable, index?: number) =>
        dispatch({
          type: Actions.setObject,
          payload: { object, index },
        }),
      [dispatch]
    ),
    setInstances: React.useCallback(
      (instances: Instance[]) =>
        dispatch({
          type: Actions.setInstances,
          payload: instances,
        }),
      [dispatch]
    ),
    setBadInstancesCount: React.useCallback(
      (instanceId: string) =>
        dispatch({
          type: Actions.setBadInstancesCount,
          payload: instanceId,
        }),
      [dispatch]
    ),
    setPlaybackStateAll: React.useCallback(
      (state: PlaybackState) =>
        dispatch({
          type: Actions.setPlaybackStateAll,
          payload: state,
        }),
      [dispatch]
    ),
    setPlaybackState: React.useCallback(
      (control: PlaybackControl) =>
        dispatch({
          type: Actions.setPlaybackState,
          payload: control,
        }),
      [dispatch]
    ),
    setPanelLayout: React.useCallback(
      (rows: number, cols: number) =>
        dispatch({
          type: Actions.setPanelLayout,
          payload: { rows, cols },
        }),
      [dispatch]
    ),
    setSelectedPanel: React.useCallback(
      (index: number) =>
        dispatch({
          type: Actions.setSelectedPanel,
          payload: index,
        }),
      [dispatch]
    ),
    setLoaded: React.useCallback(
      (loaded: boolean, index: number) =>
        dispatch({
          type: Actions.setLoaded,
          payload: { loaded, index },
        }),
      [dispatch]
    ),
    setFiles: React.useCallback(
      (files: ShareFile[]) =>
        dispatch({
          type: Actions.setFiles,
          payload: files,
        }),
      [dispatch]
    ),
  }
}
