import { Instance } from "./types"

export const Log = {
  warn: (message: string, obj?: unknown, _?: Instance["instanceId"]) => {
    console.log(message, obj ?? "")
  },

  error: (message: string, obj?: unknown, _?: Instance["instanceId"]) => {
    alert(message)
    if (obj) console.error(message, obj)
  },

  debug: (message: string, obj?: unknown, _?: Instance["instanceId"]) => {
    console.debug(message, obj ?? "")
  },
}
