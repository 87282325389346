import { Link, useNavigate } from "react-router-dom"
import { pathList } from "../routes/pathList"
import authService from "../services/AuthService"
import userService from "../services/UserService"
import { useMatchingPath } from "../hooks"
import s from "./user.module.scss"

export default function User() {
  const navigate = useNavigate()
  const matchingPath = useMatchingPath(["lookup", "lookupDetails", "view"])

  function doLogout() {
    authService.logout()
    navigate(pathList.root.reverse())
  }

  const user = userService.user
  if (user) {
    return (
      <div>
        <div>
          {user.firstName} {user.lastName}
        </div>
        <div>
          <span className={s.link} onClick={() => doLogout()}>
            Log out
          </span>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Link to={pathList.login.reverse()}>
        {matchingPath ? "Provider Login" : "Log in"}
      </Link>
    </div>
  )
}
