import React, { useState } from "react"
import Button from "../Button"
import { Tooltip, OverlayTrigger, TooltipProps } from "react-bootstrap"
import logoSvg from "../../assets/imgs/logo.svg"

interface Props {
  link: string
  isBrandingActive?: boolean
}

const ShareLink = ({ link, isBrandingActive }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setShowTooltip(true)
      setTimeout(() => setShowTooltip(false), 2000) // Hide tooltip after 2 seconds
    })
  }

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" {...props}>
      Link copied to clipboard.
    </Tooltip>
  )

  return (
    <div className="share-link-section">
      <OverlayTrigger
        placement="top"
        overlay={renderTooltip}
        show={showTooltip}
        trigger={[]}
      >
        <Button
          variant="link"
          label="Click Here to Copy Imaging Link to Clipboard"
          className="p-0 mt-2 mb-2"
          onClick={handleCopyLink}
        />
      </OverlayTrigger>
      <p>
        You can paste the link into a text or email for sharing with others.
      </p>
      {!isBrandingActive && (
        <>
          <hr />
          <p className="dark-color fw-bold">Powered By</p>
          <div className="d-flex align-items-center grey-color logo-cap">
            <img src={logoSvg} alt="logo" className="me-3" />
            <span className="font-size mt-5">
              Patient Information Management System
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default ShareLink
