import React, { useState, ChangeEvent } from "react"
import { Form, InputGroup } from "react-bootstrap"
import eye from "./icons/eye.svg"
import sysSlash from "./icons/eye-slash.svg"
import s from "./input.module.scss"
import c from "classnames"

interface InputProps {
  type: HTMLInputElement["type"]
  name: string
  placeholder: string
  label: string
  className?: string
  defaultValue?: string
  isRequired?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  error?: string
}

const Input: React.FC<InputProps> = ({
  type,
  name,
  placeholder,
  label,
  className,
  defaultValue,
  isRequired,
  onChange,
  error,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue || "")
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((s) => !s)
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (onChange) {
      onChange?.(e)
    }
  }

  return (
    <Form.Group className="mb-3">
      <Form.Label className={`${s.loginLabel} grey-color`}>{label}</Form.Label>
      <InputGroup className="position-relative">
        <Form.Control
          className={c(s.loginInput, "fw-normal", {
            [s.errorInput]: error,
            [className ?? ""]: className,
          })}
          type={showPassword ? "text" : type}
          name={name}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          required={isRequired}
          isInvalid={!!error}
        />
        {type === "password" && (
          <img
            src={!showPassword ? eye : sysSlash}
            alt="toggle visibility"
            onClick={togglePasswordVisibility}
            className={s.toggleShowIcon}
          />
        )}
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  )
}

export default Input
