import React, { useMemo } from "react"
import { Displayable } from "../../../services/ShareService/types"
import { Instance } from "../types"
import s from "./OhifViewer.module.scss"

interface OhifViewerProps {
  objects: Displayable[]
  ohifUrl: string
}

export const OhifViewer = ({ objects, ohifUrl }: OhifViewerProps) => {
  const studyIds = useMemo(() => {
    const filteredStudyIds = [
      ...new Set(
        objects.map((item: Displayable) => {
          if (item.contentType === "dicom") return (item as Instance).studyId
          return null
        })
      ),
    ]
      .filter((item) => item !== null)
      .join(",")

    return filteredStudyIds
  }, [objects])

  return (
    <div className={s.imageViewArea}>
      <iframe
        src={`${ohifUrl}/viewer?StudyInstanceUIDs=${studyIds}`}
        style={{ width: "100%", height: "100%", border: "none" }}
        allow="xr-spatial-tracking"
        title="OHIF viewer"
      />
    </div>
  )
}
