import React, { useCallback, useEffect, useState } from "react"
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  FormText,
  Row,
} from "react-bootstrap"
import { Share } from "../services/ShareService/types"
import DOBField from "./DOBField"
import { useUser } from "../hooks"

export type ShareFormProps = {
  share?: Share
  formValid?: (isValid: boolean) => void
}

const defaultExpireAfter = 30

export default function ShareForm({ share, formValid }: ShareFormProps) {
  const statusValues = ["Active", "Inactive"]
  const user = useUser()
  const hasMultipleUnits = (user?.units.length ?? 0) > 1

  const [selectedUnitId, setSelectedUnitId] = useState(
    share?.unitId ?? user?.units[0]?.id
  )

  const [expireAfter, setExpireAfter] = useState<number>(defaultExpireAfter)

  const setExpire = useCallback(
    (value?: number) => setExpireAfter(value ?? defaultExpireAfter),
    [setExpireAfter]
  )

  useEffect(() => {
    const selectedUnit = selectedUnitId
      ? user?.units?.find((unit) => unit.id === selectedUnitId)
      : null
    setExpire(selectedUnit?.defaultShareDays)
  }, [selectedUnitId, setExpire, user?.units])

  return (
    <Container>
      <Row>
        <Col>
          <FormGroup className="mb-3" controlId="formFirstName">
            <FormLabel>First Name</FormLabel>
            <FormControl
              placeholder="First name"
              name="firstName"
              defaultValue={share?.firstName}
              required
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-3" controlId="formLastName">
            <FormLabel>Last Name</FormLabel>
            <FormControl
              placeholder="Last name"
              name="lastName"
              defaultValue={share?.lastName}
              required
            />
          </FormGroup>
        </Col>
        {hasMultipleUnits && (
          <Col>
            <FormGroup className="mb-3" controlId="formUnit">
              <FormLabel>Unit</FormLabel>
              <FormSelect
                aria-label="Unit"
                name="unit"
                value={selectedUnitId}
                onChange={(e) =>
                  setSelectedUnitId(parseInt(e.target.value, 10))
                }
                required
              >
                {user?.units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <DOBField
            label="Date of Birth"
            formValid={formValid}
            defaultValue={share?.dob}
          />
        </Col>
        <Col>
          <FormGroup className="mb-3" controlId="formMrNumber">
            <FormLabel>MR Number</FormLabel>
            <FormControl
              placeholder="MR Number"
              name="mrnumber"
              defaultValue={share?.mrnumber}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="mb-3" controlId="formStatus">
            <FormLabel>Status</FormLabel>
            <FormSelect
              aria-label="Status"
              name="status"
              defaultValue={share?.status}
              required
            >
              {statusValues.map((s) => (
                <option key={s} value={s}>
                  {s}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup className="mb-3" controlId="formNotes">
          <FormLabel>Notes</FormLabel>
          <FormControl
            as="textarea"
            placeholder="Notes"
            name="notes"
            defaultValue={share?.notes}
          />
        </FormGroup>
        <FormGroup className="mb-3" controlId="formExtend">
          <FormLabel>Expires after (days)</FormLabel>
          <FormControl
            type="number"
            min="1"
            max="365"
            placeholder="Expires in 1-365 days (from now)"
            name="expireAfter"
            required={!!share}
            value={expireAfter}
            onChange={(e) => setExpire(parseInt(e.target.value))}
          />
          <ShareExpDate />
        </FormGroup>
      </Row>
    </Container>
  )
}

interface ShareExpDateProps {
  share?: Share
}

const ShareExpDate = ({ share }: ShareExpDateProps) => {
  if (!share?.created) {
    return <></>
  }
  return (
    <FormText>
      Current expiration date: {share.created.toLocaleString()}
    </FormText>
  )
}
