import apiService, { ApiError } from "./ApiService"
import userService from "./UserService"

export interface Unit {
  id: number
  name: string
  logo: string
  defaultShareDays: number
}

export interface User {
  email: string
  firstName: string
  lastName: string
  units: Unit[]
}

export interface LoginInfo {
  email: string
  password: string
}

interface Tokens {
  access: string
  refresh: string
}

class AuthService {
  private localStorageKey = "tokens"

  public get tokens(): Tokens | undefined {
    const tokenString = localStorage.getItem(this.localStorageKey)
    if (tokenString) {
      try {
        return JSON.parse(tokenString)
      } catch (err) {
        console.error(err)
      }
    }
    return undefined
  }

  public set tokens(token: Tokens | undefined) {
    if (!token) {
      localStorage.removeItem(this.localStorageKey)
      userService.user = undefined
    } else {
      localStorage.setItem(this.localStorageKey, JSON.stringify(token))
    }
  }

  async login(data: LoginInfo): Promise<Tokens | ApiError> {
    return await apiService.request({
      endpoint: "user/token/",
      method: "post",
      data,
    })
  }

  async refreshAccessToken(): Promise<Tokens | ApiError | undefined> {
    const tokens = this.tokens
    if (!tokens) {
      return
    }
    this.tokens = undefined // clear to prevent endless refresh
    const response = await apiService.request<Tokens>({
      endpoint: "user/token/refresh/",
      method: "post",
      data: { refresh: tokens.refresh },
    })
    if (response instanceof ApiError) {
      if (response.authRequired) {
        return
      }
      return response
    }
    const newAccess = response.access
    return (this.tokens = { ...tokens, access: newAccess })
  }

  logout() {
    this.tokens = undefined
  }
}

const authService = new AuthService()
export default authService
