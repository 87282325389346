import { AxiosError, AxiosRequestConfig, Method, ResponseType } from "axios"
import { redirect } from "react-router-dom"
import { API_HOSTNAME } from "../config"
import { pathList } from "../routes/pathList"
import { BaseApiService } from "./BaseApiService"

export class ApiError {
  authRequired = false
  notFound = false
  originalError: Error
  errorMessage?: string

  constructor(error: Error) {
    if (error instanceof AxiosError) {
      this.authRequired = error.response?.status === 401
      this.notFound = error.response?.status === 404
      this.errorMessage = error.response?.data
    }
    this.originalError = error
  }
}

export function handleApiError(error: ApiError): Response {
  if (error.authRequired) {
    const url = pathList.login.reverse()
    return redirect(url)
  }
  throw error.originalError
}

export function handleNotFoundError(): never {
  throw new Response("", {
    status: 404,
    statusText: "Not Found",
  })
}

interface RequestParams {
  endpoint: string
  method?: Method
  data?: unknown
  hasFiles?: boolean
  responseType?: ResponseType
}

class ApiService extends BaseApiService {
  protected baseUrl = `${API_HOSTNAME}api/`

  public async request<T>(params: RequestParams): Promise<T | ApiError> {
    params.method ??= "get"

    const config: AxiosRequestConfig = {
      method: params.method,
      url: params.endpoint,
    }
    if (params.data) {
      config.data = params.data
    }
    if (params.hasFiles) {
      config.headers ??= {}
      config.headers["Content-Type"] = "multipart/form-data"
    }
    if (params.responseType) {
      config.responseType = params.responseType
    }
    try {
      const response = await this.client.request(config)
      return response.data
    } catch (error) {
      return new ApiError(error as Error)
    }
  }
}

const apiService = new ApiService()
export default apiService
