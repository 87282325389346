import React from "react"
import s from "./Thumbnails.module.scss"
import PdfRenderer from "../components/PdfRenderer"
import Video from "../components/Video"
import Image from "../components/Image"
import loadingGif from "../200.gif"
import { useDispatch } from "../context"
import { ImageFile, ImageType, PdfFile, Instance, VideoFile } from "../types"

import { Displayable } from "../../../services/ShareService/types"

interface FileViewerProps {
  object: Displayable
  setObject: (object: Displayable) => void
}

interface ImageProps {
  instance: Instance
  onSelect: (instance: Instance) => void
}

const DicomImage = ({ instance, onSelect }: ImageProps) => {
  const { state, setObject, setBadInstancesCount } = useDispatch()
  const { getImageStack } = useDispatch()

  const [imageUrl, setImageUrl] = React.useState<string>(loadingGif)
  const imageStack = React.useMemo(
    () => getImageStack(instance),
    [getImageStack, instance]
  )
  React.useEffect(() => {
    imageStack.getThumbnailUrl().then((url) => setImageUrl(url))
  }, [imageStack])

  const handleRenderingError = () => {
    setObject(state.instances[0])
    setBadInstancesCount(instance.instanceId)
    console.log("Unable to render instance: %s", instance.instanceId)
  }

  return (
    <img
      alt={instance.instanceId}
      src={imageUrl}
      className={s.img}
      id={instance.instanceId}
      onError={handleRenderingError}
      onClick={() => onSelect(instance)}
    />
  )
}

export const FileViewer = ({ object, setObject }: FileViewerProps) => {
  if (!object) return <></>
  if (object.contentType === "dicom") {
    const instance = object as Instance
    return (
      <DicomImage instance={instance} onSelect={() => setObject(instance)} />
    )
  }
  if (object.contentType === "application/pdf") {
    const pdfFile = object as PdfFile
    return (
      <div className={s.files} onClick={() => setObject(pdfFile)}>
        <PdfRenderer id={pdfFile.id} link={pdfFile.link} isThumbnail />
      </div>
    )
  }
  if (Object.values(ImageType).includes(object.contentType as ImageType)) {
    const imageFile = object as ImageFile
    return (
      <div className={s.files} onClick={() => setObject(imageFile)}>
        <Image id={imageFile.id} link={imageFile.link} />
      </div>
    )
  }
  if (object.contentType === "video/mp4") {
    const videoFile = object as VideoFile
    return (
      <div className={s.files} onClick={() => setObject(videoFile)}>
        <Video id={videoFile.id} link={videoFile.link} isThumbnail />
      </div>
    )
  }

  console.error(`Unknown content type ${object.contentType}`)
  return <></>
}
