import { api } from "dicomweb-client"
import dcmjs from "dcmjs"
import { calculateSUVScalingFactors } from "@cornerstonejs/calculate-suv"
import { getPTImageIdInstanceMetadata } from "./getPTImageIdInstanceMetadata"
import { utilities } from "@cornerstonejs/core"
import cornerstoneDICOMImageLoader from "@cornerstonejs/dicom-image-loader"

import { addInstance } from "./ptScalingMetaDataProvider"
import getPixelSpacingInformation from "./getPixelSpacingInformation"
import { convertMultiframeImageIds } from "./convertMultiframeImageIds"
import removeInvalidTags from "./removeInvalidTags"

const { DicomMetaDictionary } = dcmjs.data
const { calibratedPixelSpacingMetadataProvider } = utilities

const SOP_INSTANCE_UID = "00080018"
const SERIES_INSTANCE_UID = "0020000E"
const MODALITY = "00080060"
const PHOTOMETRIC = "00280004"
const SERIES_NUMBER = "00200011"
const ACQUISITION_NUMBER = "00200012"
const INSTANCE_NUMBER = "00200013"

// Define a custom comparison function for sorting
const customSort = (a, b) => {
  const getNumericValue = (value) =>
    value ? parseFloat(value) : Number.MAX_SAFE_INTEGER

  // Extract values for comparison, defaulting to Number.MAX_SAFE_INTEGER if undefined
  const seriesA = getNumericValue(a[SERIES_NUMBER]?.Value?.[0])
  const seriesB = getNumericValue(b[SERIES_NUMBER]?.Value?.[0])
  const acquisitionA = getNumericValue(a[ACQUISITION_NUMBER]?.Value?.[0])
  const acquisitionB = getNumericValue(b[ACQUISITION_NUMBER]?.Value?.[0])
  const instanceA = getNumericValue(a[INSTANCE_NUMBER]?.Value?.[0])
  const instanceB = getNumericValue(b[INSTANCE_NUMBER]?.Value?.[0])

  // Compare series numbers, then acquisition numbers, then instance numbers
  if (seriesA !== seriesB) {
    return seriesA - seriesB
  }
  if (acquisitionA !== acquisitionB) {
    return acquisitionA - acquisitionB
  }
  return instanceA - instanceB
}

/**
/**
 * Uses dicomweb-client to fetch metadata of a study, cache it in cornerstone,
 * and return a list of imageIds for the frames.
 *
 * Uses the app config to choose which study to fetch, and which
 * dicom-web server to fetch it from.
 *
 * @returns {string[]} An array of imageIds for instances in the study.
 */

export default async function createImageIdsAndCacheMetaData({
  StudyInstanceUID,
  SeriesInstanceUID,
  SOPInstanceUID,
  wadoRsRoot,
  isSingleImage,
  client = null,
}) {
  const studySearchOptions = {
    studyInstanceUID: StudyInstanceUID,
    seriesInstanceUID: SeriesInstanceUID,
    sopInstanceUID: SOPInstanceUID,
  }

  client = client || new api.DICOMwebClient({ url: wadoRsRoot })
  let instances
  if (isSingleImage) {
    instances = await client.retrieveInstanceMetadata(studySearchOptions)
  } else {
    instances = await client.retrieveSeriesMetadata(studySearchOptions)
  }

  // for the following line docmentation please see https://dicom.innolitics.com/ciods/mr-image/image-pixel/00280004
  const isColoredImage = !(
    instances[0][PHOTOMETRIC].Value[0] === "MONOCHROME1" ||
    instances[0][PHOTOMETRIC].Value[0] === "MONOCHROME2"
  )

  const modality = instances[0][MODALITY].Value[0]
  let imageIds = instances.map((instanceMetaData) => {
    const SeriesInstanceUID = instanceMetaData[SERIES_INSTANCE_UID].Value[0]
    const SOPInstanceUIDToUse =
      SOPInstanceUID || instanceMetaData[SOP_INSTANCE_UID].Value[0]

    let prefix = "wadors:"
    if (isSingleImage) {
      prefix = "web:"
    }

    const imageId =
      prefix +
      wadoRsRoot +
      "/studies/" +
      StudyInstanceUID +
      "/series/" +
      SeriesInstanceUID +
      "/instances/" +
      SOPInstanceUIDToUse +
      `/frames/1${isSingleImage || isColoredImage ? "/rendered" : ""}`

    cornerstoneDICOMImageLoader.wadors.metaDataManager.add(
      imageId,
      instanceMetaData
    )
    return imageId
  })

  // Sort the imageIds array using the custom comparison function
  imageIds.sort((a, b) =>
    customSort(
      cornerstoneDICOMImageLoader.wadors.metaDataManager.get(a),
      cornerstoneDICOMImageLoader.wadors.metaDataManager.get(b)
    )
  )

  // if the image ids represent multiframe information, creates a new list with one image id per frame
  // if not multiframe data available, just returns the same list given
  imageIds = convertMultiframeImageIds(imageIds)

  imageIds.forEach((imageId) => {
    let instanceMetaData =
      cornerstoneDICOMImageLoader.wadors.metaDataManager.get(imageId)

    // It was using JSON.parse(JSON.stringify(...)) before but it is 8x slower
    instanceMetaData = removeInvalidTags(instanceMetaData)

    if (instanceMetaData) {
      // Add calibrated pixel spacing
      const metadata = DicomMetaDictionary.naturalizeDataset(instanceMetaData)
      const pixelSpacing = getPixelSpacingInformation(metadata)

      if (pixelSpacing) {
        calibratedPixelSpacingMetadataProvider.add(imageId, {
          rowPixelSpacing: parseFloat(pixelSpacing[0]),
          columnPixelSpacing: parseFloat(pixelSpacing[1]),
        })
      }
    }
  })

  // we don't want to add non-pet
  // Note: for 99% of scanners SUV calculation is consistent bw slices
  if (modality === "PT") {
    const InstanceMetadataArray = []
    imageIds.forEach((imageId) => {
      const instanceMetadata = getPTImageIdInstanceMetadata(imageId)

      // TODO: Temporary fix because static-wado is producing a string, not an array of values
      // (or maybe dcmjs isn't parsing it correctly?)
      // It's showing up like 'DECY\\ATTN\\SCAT\\DTIM\\RAN\\RADL\\DCAL\\SLSENS\\NORM'
      // but calculate-suv expects ['DECY', 'ATTN', ...]
      if (typeof instanceMetadata.CorrectedImage === "string") {
        instanceMetadata.CorrectedImage =
          instanceMetadata.CorrectedImage.split("\\")
      }

      if (instanceMetadata) {
        InstanceMetadataArray.push(instanceMetadata)
      }
    })
    if (InstanceMetadataArray.length) {
      try {
        const suvScalingFactors = calculateSUVScalingFactors(
          InstanceMetadataArray
        )
        InstanceMetadataArray.forEach((instanceMetadata, index) => {
          addInstance(imageIds[index], suvScalingFactors[index])
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  return imageIds
}
