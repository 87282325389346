import { LoaderFunctionArgs } from "@remix-run/router/dist/utils"
import React from "react"
import {
  Alert,
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap"
import { useActionData, useLoaderData, useSubmit } from "react-router-dom"
import { Instance } from "../../components/DicomViewer/types"
import { ApiError, handleApiError } from "../../services/ApiService"
import shareService from "../../services/ShareService"
import { PublicShare, ShareFile } from "../../services/ShareService/types"
import { convertDateFormat, isInThePast } from "../../utils"
import { BaseView } from "../view"
import HelpText from "./helpText"
import DOBField from "../../components/DOBField"

interface LoaderData {
  code: string
}

interface ShareLookup {
  share?: PublicShare
  instances?: Instance[]
  files?: ShareFile[]
}

interface FileDetailsProps extends ShareLookup {
  dob: string
}

interface ActionData extends ShareLookup {
  notFound?: boolean
  errorMessage?: string
}

function NotFound({
  notFound,
  errorMessage,
}: {
  notFound?: boolean
  errorMessage?: string
}) {
  if (!notFound) {
    return <></>
  }
  return (
    <Container>
      <Row>
        <Alert variant="danger">{errorMessage}</Alert>
      </Row>
    </Container>
  )
}

const FileDetails = ({ share, instances, files, dob }: FileDetailsProps) => {
  const [isfileDownloading, setIsFileDownloading] = React.useState(false)
  const [showViewer, setShowViewer] = React.useState(false)

  if (!share) {
    return <></>
  }

  const contacts = [<span key="c-0">{share.unit.name}</span>]
  if (share.unit.phone) {
    contacts.push(<a href={`tel:${share.unit.phone}`}>{share.unit.phone}</a>)
  }
  if (share.unit.email) {
    contacts.push(<a href="mailto:{share.unit.email }">{share.unit.email}</a>)
  }

  const contactElement = contacts.reduce((prev, cur) => (
    <>
      {prev}
      <span>, </span>
      {cur}
    </>
  ))

  const downloadFiles = async () => {
    setIsFileDownloading(true)

    await shareService.filesDownload({
      code: share.code,
      dob: convertDateFormat(dob),
    })

    setIsFileDownloading(false)
  }

  return (
    <>
      <Container>
        {isInThePast(new Date(share.expires.toLocaleString())) ? (
          <Row>
            <Alert variant="danger">
              This share is expired. Please contact the provider to re-enable
              this share.
            </Alert>
          </Row>
        ) : null}
        <Row>
          <Col lg={2}>Expires after:</Col>
          <Col>{share.expires.toLocaleString()}</Col>
        </Row>
        <Row>
          <Col lg={2}>Owner:</Col>
          <Col>{contactElement}</Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <hr />
        </Row>
        <Row className="mb-3">
          <h4>View Images in Browser</h4>
          <p>
            Click the gray button below to view the images online now in your
            browser.
          </p>
          <button
            type="button"
            onClick={() => setShowViewer(true)}
            className="btn btn-secondary"
          >
            View Images in Browser
          </button>
        </Row>
        <Row>
          <hr />
        </Row>
        <Row>
          <h4>Download Files for Storage (OPTIONAL)</h4>
          <p>
            Click the blue button below to download all of the images to your
            computer. You will need to install a SEPARATE viewer to view the
            downloaded files (see Help Instructions below). Viewing the zip file
            is optional - your files can be viewed immediately by clicking "View
            Images in Browser" above.
          </p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={downloadFiles}
            disabled={isfileDownloading}
          >
            {isfileDownloading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Downloading (this may take some time) ...
              </>
            ) : (
              <>Download</>
            )}
          </button>
        </Row>
      </Container>
      <Modal show={showViewer} fullscreen onHide={() => setShowViewer(false)}>
        <Modal.Body>
          <BaseView
            instances={instances ?? []}
            files={files ?? []}
            isOhifActive={share.isOhifActive}
            ohifUrl={share.ohifUrl}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

const Lookup = () => {
  const { code } = useLoaderData() as LoaderData
  const { share, instances, files, notFound, errorMessage } =
    (useActionData() as ActionData | undefined) || {}

  const submit = useSubmit()

  const codeRef = React.useRef<HTMLInputElement>(null)
  const dobRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (code) {
      dobRef.current?.focus()
    } else {
      codeRef.current?.focus()
    }
  }, [code])

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (
        ((e.nativeEvent as SubmitEvent).submitter as HTMLInputElement)?.name ===
        "lookup"
      ) {
        const form = e.currentTarget
        const dobValue = form.date.value

        // Convert the date to a specific format here
        const formattedDate = convertDateFormat(dobValue)

        // Update the date input value with the formatted date
        form.dob.value = formattedDate
        // Submit the form
        e.preventDefault()
        submit(form)
      }
    },
    [submit]
  )

  return (
    <form method="post" onSubmit={onSubmit} target="_blank" rel="noreferrer">
      <Container>
        <Row>
          <FormGroup className="mb-3" controlId="formCode">
            <FormLabel>Code</FormLabel>
            <FormControl
              placeholder="Code"
              name="code"
              defaultValue={code}
              required
              ref={codeRef}
            />
          </FormGroup>
          <DOBField
            inputRef={dobRef}
            label="Patient Date of Birth"
            fieldName="date"
          />
          <FormControl name="dob" type="hidden" />
        </Row>
        <Row className="justify-content-md-center mb-3">
          <Col md="1">
            <FormGroup>
              <Button variant="primary" type="submit" name="lookup">
                Lookup
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Container>
      <NotFound notFound={notFound} errorMessage={errorMessage} />
      <FileDetails
        share={share}
        instances={instances}
        files={files}
        dob={dobRef.current?.value ?? ""}
      />
      <br />
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <HelpText />
          </Col>
        </Row>
        <Row></Row>
      </Container>
    </form>
  )
}

Lookup.action = async ({
  request,
}: LoaderFunctionArgs): Promise<ActionData | Response> => {
  const formData = Object.fromEntries(await request.formData())
  const code = formData.code as string
  const share = await shareService.lookup(code, formData.dob as string)
  if (share instanceof ApiError) {
    if (share.notFound) {
      return { notFound: true, errorMessage: share.errorMessage }
    }
    return handleApiError(share)
  }

  // load instance data
  const instances = await shareService.instances(code)
  if (instances instanceof ApiError) {
    return handleApiError(instances)
  }

  const files = await shareService.filesByCode(code)
  if (files instanceof ApiError) {
    return handleApiError(files)
  }

  return { share, instances, files }
}

Lookup.loader = async ({ params }: LoaderFunctionArgs) => {
  return { code: params.code }
}

export default Lookup
