import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { RouterProvider } from "react-router-dom"
import router from "./routes"

library.add(fas)

function App() {
  return <RouterProvider router={router} />
}

export default App
