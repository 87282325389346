import React from "react"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/react-splide/css"
import { Container, Row, Col } from "react-bootstrap"
import "./slider.module.scss"

type Slide = {
  img: string
  heading: string
  text: string
}

type SliderProps = {
  slides: Slide[]
}

const Slider: React.FC<SliderProps> = ({ slides }) => {
  return (
    <div>
      <Splide
        options={{
          type: "loop",
          perPage: 1,
          perMove: 1,
          gap: "1rem",
          pagination: true,
          arrows: false,
          autoplay: true,
        }}
        className="custom-splide"
      >
        {slides.map((slide, index) => (
          <SplideSlide key={index}>
            <Container fluid className="slider-container">
              <Row className="d-flex align-items-center justify-content-center slide-content">
                <Col className="text-center pt-4">
                  <div className="slide-image-wrapper pb-5">
                    <img
                      src={slide.img}
                      alt={slide.heading}
                      className="slide-image img-fluid"
                    />
                  </div>
                  <h2 className="slide-heading text-white mt-3">
                    {slide.heading}
                  </h2>
                  <p className="slide-text mt-3 text-white pb-5">
                    {slide.text}
                  </p>
                </Col>
              </Row>
            </Container>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  )
}

export default Slider
