import React from "react"
import "./patientProfile.module.scss"
import { Container, Row, Col, Card } from "react-bootstrap"
import "../../index.css"
import { useLocation } from "react-router-dom"
import s from "../../components/PatientProfile/patientProfile.module.scss"
import { convertDateFormat } from "../../utils"
import c from "classnames"
import ShareLink from "../ShareLink"
import { PublicShare } from "../../services/ShareService/types"

interface Props {
  isSmallScreen: boolean
}

const PatientProfileInfo = ({ isSmallScreen }: Props) => {
  const location = useLocation()
  const share: PublicShare = location.state.share || {}

  return (
    <Container className={isSmallScreen ? " p-0" : "h-100"} fluid="md">
      <Row className="h-100">
        <Col>
          <Card className="shadow-sm border-0 h-100">
            <Card.Body>
              <div className="dark-color fw-bold light-bg ">
                {share.unit.logo ? (
                  <div className="logo d-inline-block d-flex align-items-center">
                    <img
                      src={share.unit.logo}
                      alt="{share.unit.name}"
                      className="m-2"
                    />
                    <span className="fw-bold m-2">{share.unit.name}</span>
                  </div>
                ) : (
                  <span>{share.unit.name}</span>
                )}
              </div>
              <div className="mt-3">
                <h6 className="dark-color fw-bold font-size">
                  Personal Information
                </h6>
                <Row className="mt-2">
                  <Col xs={6} className="text-heading-color font-size">
                    Full Name
                  </Col>
                  <Col xs={6} className="text-heading-color font-size">
                    Date of Birth
                  </Col>
                  <Col xs={6} className="text-data-color">
                    {share.firstName} {share.lastName}
                  </Col>
                  <Col xs={6} className="text-data-color">
                    {convertDateFormat(share.dob)}
                  </Col>
                </Row>
                <hr className={c(s.customHr)} />
                <h6 className="dark-color fw-bold font-size">
                  Account Information
                </h6>
                <Row className="mt-2">
                  <Col xs={6} className="text-heading-color font-size">
                    Provider
                  </Col>
                  <Col xs={6} className="text-heading-color font-size">
                    Available Until
                  </Col>
                  <Col xs={6} className="text-data-color">
                    {share.unit.name}
                  </Col>
                  <Col xs={6} className="text-data-color">
                    {share.expires.toLocaleString()}
                  </Col>
                </Row>
                <hr className={s.customHr} />
                <h6 className="dark-color fw-bold font-size">Description</h6>
                <p className="text-muted font-size">{share.notes}</p>
                <hr className={c(s.customHr)} />
                <ShareLink
                  link={window.location.href}
                  isBrandingActive={true}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default PatientProfileInfo
