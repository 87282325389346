import React, { useEffect, useRef, useState } from "react"

import { CinePlayer, makeCinePlayer } from "./CinePlayer"
import { useDispatch } from "./context"
import { ParsedDicomMetadata } from "./DicomMetadata"
import { drawFrame } from "./drawing"
import { Instance } from "./types"
import { Viewer } from "./Viewer"

import s from "./ImagePanel.module.scss"
import { Log } from "./logger"
import ViewerToolbar from "./ViewerToolbar"

interface OwnProps {
  //extends PanelState {
  index: number
  viewer: React.RefObject<HTMLElement>
}

export function ImagePanel({ index, viewer }: OwnProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const divRef = useRef<HTMLDivElement | null>(null)

  const [images, setImages] = useState<ImageBitmap[]>([])
  const [cinePlayer, setCinePlayer] = useState<CinePlayer | null>(null)
  const [metadata, setMetadata] = useState<ParsedDicomMetadata | null>(null)

  const {
    state,
    getImageStack,
    setLoaded,
    setPlaybackState,
    setSelectedPanel,
  } = useDispatch()

  const width = React.useMemo(
    () => (100 / state.layout.cols).toString() + "%",
    [state.layout.cols]
  )
  const { object, isSelected, isLoaded, playbackInfo } = React.useMemo(
    () => state.panels[index],
    [index, state.panels]
  )
  const imageStack = React.useMemo(
    () => object && getImageStack(object as Instance),
    [getImageStack, object]
  )

  useEffect(() => {
    if (!(object && imageStack)) {
      return
    }
    setLoaded(false, index)
    cinePlayer?.stop()
    setCinePlayer(null)
    imageStack
      .getFirstFrame()
      .then((img: ImageBitmap) => {
        if (canvasRef.current) {
          canvasRef.current.width = img.width
          canvasRef.current.height = img.height
        }
        setImages([img])
        return imageStack.getMetadata()
      })
      .then((metadata: ParsedDicomMetadata) => {
        setMetadata(metadata)
        return imageStack.getFrameImages() // start loading all the images
      })
      .then((imgs: ImageBitmap[]) => {
        setLoaded(true, index)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object])

  useEffect(() => {
    if (!(isLoaded && imageStack)) {
      return
    }
    imageStack.getFrameImages().then((imgs) => {
      setImages(imgs)
      if (!(canvasRef.current && metadata && object)) {
        return
      }
      const newCinePlayer = makeCinePlayer(
        canvasRef.current,
        imgs,
        metadata.cineRate
      )
      cinePlayer?.stop()
      setCinePlayer(newCinePlayer)
      setPlaybackState({ state: imgs.length > 1 ? "play" : "stop", index })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  useEffect(() => {
    Log.debug(`playbackInfo changed: ${playbackInfo.state}`)
    // stupid hack
    const s = playbackInfo.state.split("-")[0]
    switch (s) {
      case "play":
        cinePlayer?.play()
        break
      case "stop":
        cinePlayer?.stop()
        break
      case "last":
        cinePlayer?.lastFrame()
        break
      case "first":
        cinePlayer?.firstFrame()
        break
      case "next":
        cinePlayer?.nextFrame()
        break
      case "prev":
        cinePlayer?.prevFrame()
        break
    }
  }, [cinePlayer, playbackInfo])

  const drawLoadingBar = () => {
    const percentLoaded = imageStack?.getPercentLoaded() ?? 0
    const canvas = canvasRef.current
    if (!canvas) return
    drawFrame(canvas, images, 0)
    const ctx = canvas.getContext("2d")
    if (!ctx) return

    // Draw the semi-transparent overlay
    ctx.fillStyle = "rgba(0,0,0,0.5)"
    ctx.fillRect(0, 0, canvas.width, canvas.height * (1 - percentLoaded))

    // Draw the "Loading" text
    ctx.font = "48px Arial" // You can adjust the font size and style
    ctx.fillStyle = "white" // Text color
    ctx.textAlign = "center" // Text alignment
    ctx.fillText("Loading...", canvas.width / 2, canvas.height / 2) // Position and text content
  }

  const uiLoop = () => {
    if (!isLoaded || !cinePlayer) drawLoadingBar()
    else {
      // if (!cinePlayer!.isPlaying()) {
      const currFrame = cinePlayer.currentFrame()
      if (canvasRef.current) {
        drawFrame(canvasRef.current, images, currFrame)
      }
    }
  }

  useEffect(() => {
    if (images.length > 0) {
      const interval = window.setInterval(uiLoop, 1000 / 60)
      return () => window.clearInterval(interval)
    }
  })

  return (
    <div
      className={`${s.imagePanel}
                          ${isSelected ? s.selected : ""}
                          ${
                            parseInt(width) === 100
                              ? s.full
                              : parseInt(width) === 50
                              ? s.half
                              : parseInt(width) === 33
                              ? s.third
                              : s.full
                          }`}
      onClick={() => setSelectedPanel(index)}
      ref={divRef}
    >
      <ViewerToolbar viewer={viewer} imagesLength={images.length} />
      <Viewer object={object} canvasRef={canvasRef} />
    </div>
  )
}
