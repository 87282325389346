import { Viewer, Worker } from "@react-pdf-viewer/core"
import { rotatePlugin } from "@react-pdf-viewer/rotate"
import { PdfFile } from "../types"
import "@react-pdf-viewer/core/lib/styles/index.css"
import s from "./Pdf.module.scss" // Import your SCSS file

interface Prop {
  link: PdfFile["link"]
  id: PdfFile["id"]
  isThumbnail?: boolean
}

const PdfRenderer = ({ link, id, isThumbnail }: Prop) => {
  const rotatePluginInstance = rotatePlugin()
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance

  return (
    // In future migrate the below worker.js file in the project so that we don't have to make a request everytime.
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.js">
      {isThumbnail ? (
        <Viewer fileUrl={link} />
      ) : (
        <div className={`rpv-core__viewer ${s.pdfContainer}`}>
          <div className={s.header}>
            <div className={s.rotateButton}>
              <RotateBackwardButton />
            </div>
            <div className={s.rotateButton}>
              <RotateForwardButton />
            </div>
          </div>
          <div className={s.pdfViewer}>
            <Viewer fileUrl={link} plugins={[rotatePluginInstance]} />
          </div>
        </div>
      )}
    </Worker>
  )
}
export default PdfRenderer
