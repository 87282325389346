import c from "classnames"
import React from "react"
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormGroup,
  Row,
  ProgressBar,
} from "react-bootstrap"
import { Form as ReactForm, useNavigate, useParams } from "react-router-dom"
import DropZone from "../components/DropZone"
import { UploadFileList } from "../components/DropZone/uploadFileList"
import ShareForm from "../components/ShareForm"
import s from "../components/ShareForm.module.scss"
import { pathList } from "../routes/pathList"
import { ApiError, handleApiError } from "../services/ApiService"
import shareService from "../services/ShareService"
import { convertDateFormat, formatFileSize } from "../utils"
import { ErrorResponse, Response } from "../services/ShareService/types"

interface FormValid {
  files: boolean
  shareForm: boolean
}

const Create = () => {
  const [isDropActive, setIsDropActive] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])
  const [currentUploadedFiles, setCurrentUploadedFiles] =
    React.useState<number>(0)
  const [formValid, setFormValid] = React.useState<FormValid>({
    files: true,
    shareForm: false,
  })
  const [inProgress, setInProgress] = React.useState(false)
  const [failedUploadFiles, setFailedUploadFiles] = React.useState<File[]>([])
  const [finishUploading, setFinishUploading] = React.useState(false)

  const updateFormValid = React.useCallback(
    (o: Partial<FormValid>) => setFormValid((f) => ({ ...f, ...o })),
    []
  )

  const isFormValid = React.useCallback((): boolean => {
    return Object.values(formValid).every((v) => v)
  }, [formValid])

  const onDragStateChange = React.useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive)
  }, [])

  const onFilesDrop = React.useCallback(
    (droppedFiles: File[]) => {
      setFiles((existingFiles) => [...existingFiles, ...droppedFiles])
      updateFormValid({ files: true })
    },
    [updateFormValid]
  )

  const params = useParams()
  const navigate = useNavigate()

  const redirect = React.useCallback(() => {
    navigate(pathList.list.reverse())
  }, [navigate])

  React.useEffect(() => {
    if (finishUploading && !failedUploadFiles.length) {
      // if no failed uploads then redirect to the list page.
      redirect()
    }
  }, [failedUploadFiles.length, finishUploading, redirect])

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (inProgress || params.id) {
      return
    }
    setInProgress(true)
    try {
      if (!isFormValid()) {
        return
      }

      const formData = new FormData(e.currentTarget)
      formData.set("dob", convertDateFormat(formData.get("dob") as string))

      const response = await shareService.create(formData)
      if (response instanceof ApiError) {
        return handleApiError(response)
      }
      await uploadFiles(response.id) // Await the file uploads
    } finally {
      setInProgress(false)
      setFinishUploading(true)
    }
  }

  const uploadFiles = async (shareId: string) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const formData = new FormData()
      formData.append("files", file)

      let retries = 3 // Define the number of retries

      while (retries > 0) {
        try {
          // Send a POST request to the new endpoint for file upload
          const response = await shareService.uploadFile(formData, shareId)
          // if its an ApiError then it will not come to this line and hence we can type cast the 'response'
          if ((response as Response).status === "error") {
            throw new Error((response as ErrorResponse).message)
          }
          // Update the progress indicator here
          setCurrentUploadedFiles((prevCount) => prevCount + 1)
          break // Upload succeeded, exit the retry loop
        } catch (error) {
          // Handle upload error, but don't reattempt here
          retries-- // Decrement the number of retries
          if (retries === 0) {
            // If retries are exhausted, handle the error or log it
            console.error("Upload failed after multiple retries:", error)
            setFailedUploadFiles((failedFiles) => [...failedFiles, file])
          }
        }
      }
    }
  }

  const updateProgress = (current: number): number => {
    const percent = ((current / files.length) * 100).toFixed(2)
    return parseInt(percent, 10) // Parse the percentage as an integer
  }

  return (
    <ReactForm method="post" encType="multipart/form-data" onSubmit={onSubmit}>
      <ShareForm
        formValid={(isValid: boolean) =>
          updateFormValid({ shareForm: isValid })
        }
      />
      <Container>
        <Row>
          <Col md={8}>
            <FormGroup className="mb-3" controlId="formFile">
              <FloatingLabel label="Drop your files or directory here">
                <DropZone
                  onDragStateChange={onDragStateChange}
                  onFilesDrop={onFilesDrop}
                  className={c("form-control", s.dropArea, {
                    [s.active]: isDropActive,
                  })}
                ></DropZone>
              </FloatingLabel>
            </FormGroup>
          </Col>
          <Col>
            <UploadFiles
              files={files}
              hasError={!formValid.files}
              heading="Files to upload"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={inProgress ? "5" : "1"}>
            <FormGroup>
              {inProgress ? (
                <>
                  <p className={s.progressText}>
                    Uploading file {currentUploadedFiles}/{files.length} --{" "}
                    {updateProgress(currentUploadedFiles)}% done
                  </p>
                  <ProgressBar
                    animated
                    now={updateProgress(currentUploadedFiles)}
                    className={s.progress}
                  />
                </>
              ) : (
                <>
                  {finishUploading && failedUploadFiles.length > 0 ? (
                    <Button variant="primary" type="button" onClick={redirect}>
                      Ok
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={inProgress}
                    >
                      Create
                    </Button>
                  )}
                </>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            {failedUploadFiles.length > 0 && (
              <UploadFiles
                files={failedUploadFiles}
                hasError={true}
                heading="Files failed to upload"
              />
            )}
          </Col>
        </Row>
      </Container>
    </ReactForm>
  )
}

interface UploadFileListProps {
  files: File[]
  hasError: boolean
  heading: string
}

const UploadFiles = React.memo(
  ({ files, hasError, heading }: UploadFileListProps) => {
    return (
      <div className={s.fileList}>
        <UploadFileListHeader
          files={files}
          hasError={hasError}
          heading={heading}
        />
        <UploadFileList files={files} />
      </div>
    )
  }
)

const UploadFileListHeader = ({
  files,
  hasError,
  heading,
}: UploadFileListProps) => {
  if (files.length === 0) {
    return <h3 className={c({ [s.error]: hasError })}>No files to upload</h3>
  }
  return (
    <h3>
      {heading}: {files.length} (
      {formatFileSize(files.map((f) => f.size).reduce((p, c) => p + c, 0))})
    </h3>
  )
}

export default Create
