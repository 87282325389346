import { Image, Study } from "../../components/DicomViewer/types"
import { Unit } from "../AuthService"

export type DisplayableType = "dicom" | "application/pdf" | Image | "video/mp4"

export interface Displayable {
  pk: number // database PK
  id: string // app-level object identifier
  contentType?: DisplayableType
}

export interface ShareFile extends Displayable {
  link: string
  originalName: string
  size: number
}

export interface ShareApiObject {
  id: string
  firstName: string
  lastName: string
  mrnumber: string
  dob: string
  status: string
  created: string
  expires: string
  downloaded: number | null
  code: string
  url: string
  notes: string
  unit: Unit["id"]
  files: ShareFile[]
  isOhifActive: boolean
  ohifUrl: string
}

export class Share {
  id: string
  firstName: string
  lastName: string
  mrnumber: string
  dob: string
  status: string
  created: Date
  expires: Date
  downloaded: number | null
  code: string
  url: string
  notes: string
  files: ShareFile[]
  unitId: Unit["id"]
  isOhifActive: boolean
  ohifUrl: string

  constructor(apiObj: ShareApiObject) {
    this.id = apiObj.id
    this.firstName = apiObj.firstName
    this.lastName = apiObj.lastName
    this.mrnumber = apiObj.mrnumber
    this.dob = apiObj.dob
    this.files = []
    this.status = apiObj.status
    this.created = new Date(apiObj.created)
    this.expires = new Date(apiObj.expires)
    this.downloaded = apiObj.downloaded
    this.code = apiObj.code
    this.url = apiObj.url
    this.notes = apiObj.notes
    this.unitId = apiObj.unit
    this.files = apiObj.files
    this.isOhifActive = apiObj.isOhifActive
    this.ohifUrl = apiObj.ohifUrl
  }
}

export interface ShareUpdate {
  firstName: string
  lastName: string
  mrnumber: string
  files?: File[]
  dob: string
  status: string
  notes: string
  extend: number
}

export interface PublicShareApiObject {
  id: string
  firstName: string
  lastName: string
  mrnumber: string
  dob: string
  code: string
  expires: string
  url: string
  unit: PublicUnit
  files: ShareFile[]
  isOhifActive: boolean
  ohifUrl: string
  notes: string
}

export class PublicShare {
  id: string
  firstName: string
  lastName: string
  mrnumber: string
  dob: string
  code: string
  expires: Date
  url: string
  unit: PublicUnit
  files: ShareFile[]
  isOhifActive: boolean
  ohifUrl: string
  notes: string

  constructor(share: PublicShareApiObject) {
    this.id = share.id
    this.firstName = share.firstName
    this.lastName = share.lastName
    this.mrnumber = share.mrnumber
    this.dob = share.dob
    this.code = share.code
    this.expires = new Date(share.expires)
    this.url = share.url
    this.unit = share.unit
    this.files = share.files
    this.isOhifActive = share.isOhifActive
    this.ohifUrl = share.ohifUrl
    this.notes = share.notes
  }
}

export interface PublicUnit {
  name: string
  phone?: string
  email?: string
  logo?: string
}

export interface SuccessResponse {
  status: "ok"
  files: Array<string>
}
export interface ErrorResponse {
  status: "error"
  message: string
}

export type Response = SuccessResponse | ErrorResponse

export interface FileRequestData {
  code: Share["code"]
  dob: string
}
export function isStudy(item: Study | ShareFile): item is Study {
  return "studyId" in item && "instances" in item
}

export interface PublicShareUnit {
  unit: PublicUnit
  code: string
}
