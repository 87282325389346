import React from "react"
import JSZip from "jszip"
import { filetypemime } from "magic-bytes.js"
export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void
  onDrag?: () => void
  onDragIn?: () => void
  onDragOut?: () => void
  onDrop?: () => void
  onFilesDrop?: (files: File[]) => void
}

const collectFiles = async (item: FileSystemEntry): Promise<File> => {
  const fileEntry = item as FileSystemFileEntry
  return await new Promise<File>((resolve, reject) => {
    fileEntry.file(resolve, reject)
  })
}
const addDirectory = async (item: FileSystemEntry): Promise<File[]> => {
  const files: File[] = []
  if (item.isDirectory) {
    const dirEntry = item as FileSystemDirectoryEntry
    const directoryReader = dirEntry.createReader()
    const allEntries: FileSystemEntry[] = []
    const readEntries = async () => {
      const entries = await new Promise<FileSystemEntry[]>(
        (resolve, reject) => {
          directoryReader.readEntries(resolve, reject)
        }
      )
      if (entries.length > 0) {
        allEntries.push(...entries)
        await readEntries()
      }
    }
    await readEntries()
    for (const entry of allEntries) {
      const entryFiles = await addDirectory(entry)
      files.push(...entryFiles)
    }
  } else {
    const file = await collectFiles(item)
    files.push(file)
  }
  return files
}

const checkFile = async (item: FileSystemEntry): Promise<File[]> => {
  const excludedFiles = [/^__MACOSX/, /\/\./]
  const files: File[] = []
  const zip = new JSZip()
  const file = await collectFiles(item)
  const zipFiles = await zip.loadAsync(file)
  for (const filename in zipFiles.files) {
    if (excludedFiles.some((re) => filename.match(re))) continue
    const zipFileObject = zipFiles.files[filename]
    if (zipFileObject.dir) continue
    const fileContent = await zipFileObject.async("uint8array")
    const fileTypeMime = filetypemime(fileContent)
    const file = new File([fileContent], filename, {
      type: fileTypeMime[0] || "application/octet-stream",
    })
    files.push(file)
  }
  return files
}

const DropZone = React.memo(
  (
    props: React.PropsWithChildren<DropZoneProps> &
      React.HTMLAttributes<HTMLDivElement>
  ) => {
    const {
      onDragStateChange,
      onFilesDrop,
      onDrag,
      onDragIn,
      onDragOut,
      onDrop,
      children,
      ...divProps
    } = props

    const [isDragActive, setIsDragActive] = React.useState(false)
    const dropZoneRef = React.useRef<null | HTMLDivElement>(null)

    const mapFileListToArray = (files: FileList) => {
      const array: File[] = []

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        if (file) {
          array.push(file)
        }
      }

      return array
    }

    // Create handler for dragenter event:
    const handleDragIn = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        onDragIn?.()

        if (event.dataTransfer?.items && event.dataTransfer.items.length > 0) {
          setIsDragActive(true)
        }
      },
      [onDragIn]
    )

    const handleDragOut = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        onDragOut?.()

        setIsDragActive(false)
      },
      [onDragOut]
    )

    const handleDrag = React.useCallback(
      (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        onDrag?.()
        if (!isDragActive) {
          setIsDragActive(true)
        }
      },
      [isDragActive, onDrag]
    )

    const handleDrop = React.useCallback(
      async (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        setIsDragActive(false)
        onDrop?.()
        let files: File[] = []
        // if directory support is available
        if (event.dataTransfer && event.dataTransfer.items.length > 0) {
          const items = event.dataTransfer.items
          const promises: Promise<File[]>[] = []
          for (let i = 0; i < items.length; i++) {
            const item = items[i].webkitGetAsEntry()
            if (item?.name.toLowerCase().endsWith("zip")) {
              promises.push(checkFile(item))
            } else if (item) {
              promises.push(addDirectory(item))
            }
          }
          files = [...(await Promise.all(promises)).flat(), ...files]
        } else {
          // Fallback
          if (
            event.dataTransfer?.files &&
            event.dataTransfer.files.length > 0
          ) {
            files = mapFileListToArray(event.dataTransfer.files)
          }
        }
        onFilesDrop?.(files)
        event?.dataTransfer?.clearData()
      },
      [onDrop, onFilesDrop]
    )

    // Observe active state and emit changes:
    React.useEffect(() => {
      onDragStateChange?.(isDragActive)
    }, [onDragStateChange, isDragActive])

    React.useEffect(() => {
      const tempZoneRef = dropZoneRef?.current
      if (tempZoneRef) {
        tempZoneRef.addEventListener("dragenter", handleDragIn)
        tempZoneRef.addEventListener("dragleave", handleDragOut)
        tempZoneRef.addEventListener("dragover", handleDrag)
        tempZoneRef.addEventListener("drop", handleDrop)
      }

      return () => {
        tempZoneRef?.removeEventListener("dragenter", handleDragIn)
        tempZoneRef?.removeEventListener("dragleave", handleDragOut)
        tempZoneRef?.removeEventListener("dragover", handleDrag)
        tempZoneRef?.removeEventListener("drop", handleDrop)
      }
    }, [handleDrag, handleDragIn, handleDragOut, handleDrop])

    return (
      <div ref={dropZoneRef} {...divProps}>
        {children}
      </div>
    )
  }
)

DropZone.displayName = "DropZone"

export default DropZone
