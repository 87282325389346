import { Point } from "./types"

export interface Conversions {
  clientToCanvas: (pt: Point) => Point
  canvasToImage: (pt: Point) => Point
  imageToCanvas: (pt: Point) => Point
}

const clientToCanvas = (canvas: HTMLCanvasElement) => (pt: Point) => {
  const rect = canvas.getBoundingClientRect()
  return {
    x: ((pt.x - rect.left) * canvas.width) / canvas.clientWidth,
    y: ((pt.y - rect.top) * canvas.height) / canvas.clientHeight,
  }
}

export function drawFrame(
  canvas: HTMLCanvasElement,
  images: ImageBitmap[],
  currFrame: number
) {
  const ctx = canvas.getContext("2d")
  if (!ctx) return
  const dpi = window.devicePixelRatio
  const height = getComputedStyle(canvas)
    .getPropertyValue("height")
    .slice(0, -2)
  const width = getComputedStyle(canvas).getPropertyValue("width").slice(0, -2)
  canvas.width = parseInt(width) * dpi
  canvas.height = parseInt(height) * dpi
  const imgAspectRatio = images[currFrame].width / images[currFrame].height
  const canvasAspectRatio = canvas.width / canvas.height
  let drawLeft: number, drawTop: number, drawWidth: number, drawHeight: number
  if (canvasAspectRatio > imgAspectRatio) {
    const scaledWidth =
      (images[currFrame].width * canvas.height) / images[currFrame].height
    drawLeft = (canvas.width - scaledWidth) / 2
    drawTop = 0
    drawWidth = scaledWidth
    drawHeight = canvas.height
  } else {
    const scaledHeight =
      (images[currFrame].height * canvas.width) / images[currFrame].width
    drawTop = (canvas.height - scaledHeight) / 2
    drawLeft = 0
    drawWidth = canvas.width
    drawHeight = scaledHeight
  }
  ctx.drawImage(images[currFrame], drawLeft, drawTop, drawWidth, drawHeight)

  if (images.length > 1) {
    ctx.font = "20pt Arial"
    ctx.fillStyle = "white"
    ctx.textAlign = "left"
    ctx.fillText(`Frame ${currFrame + 1}/${images.length}`, 5, 30)
  }
  return {
    clientToCanvas: clientToCanvas(canvas),
    canvasToImage: (pt: Point) => ({
      x: ((pt.x - drawLeft) * images[currFrame].width) / drawWidth,
      y: ((pt.y - drawTop) * images[currFrame].height) / drawHeight,
    }),
    imageToCanvas: (pt: Point) => ({
      x: (pt.x / images[currFrame].width) * drawWidth + drawLeft,
      y: (pt.y / images[currFrame].height) * drawHeight + drawTop,
    }),
  }
}
