import c from "classnames"
import React, { useCallback } from "react"
import { Popover } from "react-bootstrap"
import s from "./Thumbnails.module.scss"
import { Displayable } from "../../../services/ShareService/types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState, useRef } from "react"
import Overlay from "react-bootstrap/Overlay"

interface ConfirmRemoveParams {
  object: Displayable
  onDelete: (item: Displayable) => void
}

export function RemoveObjectIcon({ object, onDelete }: ConfirmRemoveParams) {
  const [show, setShow] = useState(false)
  const target = useRef(null)
  const toggleShow = useCallback(() => setShow((s) => !s), [])

  return (
    <div>
      <button
        className={c(s.removeBtn, "btn btn-sm btn-danger")}
        onClick={() => toggleShow()}
        ref={target}
      >
        <FontAwesomeIcon className={s.icon} icon="x" />
      </button>

      <Overlay
        show={show}
        target={target.current}
        placement="top"
        containerPadding={20}
      >
        <Popover
          id={"popConfirmBox" + object.id}
          className={c("overlayPopover")}
        >
          <Popover.Header as="p">Remove?</Popover.Header>
          <Popover.Body>
            <button
              className="btn btn-danger btn-sm"
              onClick={(e) => {
                e.stopPropagation()
                onDelete(object)
              }}
            >
              Yes
            </button>
            <button
              className={c(s.noButton, "btn btn-success btn-sm ml-2")}
              onClick={() => toggleShow()}
            >
              No
            </button>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  )
}
