import React from "react"

const HelpText = () => (
  <>
    <hr />
    <h3> Help with Downloaded DICOM Files</h3>
    <p>
      If you choose to download the shared files by clicking the “Download”
      button, the shared files will be packaged and delivered as a ZIP file.
      This ZIP file contains all of the shared files, and imaging is stored as
      DICOM files. DICOM files cannot be read without a helper program. Please
      follow the steps below to view.
    </p>
    <ol>
      <li>
        Unzip the contents of the file into a directory using your computer's
        native tools
      </li>
      <li>
        Install a DICOM viewer appropriate to your type of computer. Two
        suggested ones are below (we are not affiliated with either of these
        products):
        <ul>
          <li>
            <strong>Windows:</strong> MicroDICOM -
            <a
              href="https://www.microdicom.com/downloads.html"
              target="_blank"
              rel="noreferrer"
            >
              https://www.microdicom.com/downloads.html
            </a>
          </li>
          <li>
            <strong>Mac</strong>: Horos -
            <a
              href="https://horosproject.org/download-horos/"
              target="_blank"
              rel="noreferrer"
            >
              https://horosproject.org/download-horos/
            </a>
          </li>
        </ul>
      </li>
      <li>
        Open the DICOM viewer and load the directory containing the extracted
        files, or drag and drop the directory onto the opened program
      </li>
    </ol>
  </>
)

export default HelpText
