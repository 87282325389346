import { LoaderFunctionArgs } from "@remix-run/router/dist/utils"
import React from "react"
import { Link, useLoaderData } from "react-router-dom"
import { DicomViewer, getImageStack } from "../components/DicomViewer"
import { ImageStackFactory, Instance } from "../components/DicomViewer/types"
import { ShareFile } from "../services/ShareService/types"
import { API_HOSTNAME } from "../config"
import { pathList } from "../routes/pathList"
import { ApiError, handleApiError } from "../services/ApiService"
import shareService from "../services/ShareService"
import { imageLoader } from "@cornerstonejs/core"
import { wadoURL } from "../components/DicomViewer/Cornerstone/constants"
import { isSingleImageAndStack } from "../utils"
import PatientHeader from "../components/PatientHeader/header"
import userService from "../services/UserService"

interface ViewParams {
  instances: Instance[]
  isDefaultToolbar?: boolean
  files: ShareFile[]
  isOhifActive?: boolean
  ohifUrl?: string
}

export const BaseView = ({
  instances,
  isDefaultToolbar,
  files,
  isOhifActive,
  ohifUrl,
}: ViewParams) => {
  const getImageStackMemoed = React.useCallback<ImageStackFactory>(
    (instance, batchSize) =>
      getImageStack(instance, `${API_HOSTNAME}api/share/dicom/`, batchSize),
    []
  )
  const user = userService.user

  React.useEffect(() => {
    instances.forEach(async (instance) => {
      if (isSingleImageAndStack(instance) && !isOhifActive) {
        const imageIds = [
          `web:${wadoURL}/studies/${instance.studyId}/series/${instance.seriesId}/instances/${instance.instanceId}/frames/1/rendered`,
        ]
        imageLoader.loadAndCacheImages(imageIds)
      }
    })
  }, [instances, isOhifActive])

  const dicomInstances = instances.filter((obj, index, self) => {
    if (
      !("modality" in obj) ||
      ("modality" in obj && obj.modality !== "CT" && obj.modality !== "MR")
    ) {
      return true
    }
    return (
      index ===
      self.findIndex(
        (o) =>
          "seriesId" in o &&
          "modality" in obj &&
          (obj.modality === "CT" || obj.modality === "MR") &&
          "seriesId" in obj &&
          o.seriesId === obj.seriesId
      )
    )
  })

  return (
    <>
      {!user && <PatientHeader />}
      <DicomViewer
        instances={dicomInstances}
        files={files}
        getImageStack={getImageStackMemoed}
        isDefaultToolbar={isDefaultToolbar ?? false}
        isOhifActive={isOhifActive}
        ohifUrl={ohifUrl}
        hasUser={!!user?.email}
      />
    </>
  )
}

const View = () => {
  const { instances, files } = useLoaderData() as ViewParams

  return (
    <div>
      <p style={{ textAlign: "center" }}>
        <Link to={pathList.list.reverse()}>Back to list</Link>
      </p>
      <BaseView instances={instances} files={files} isDefaultToolbar />
    </div>
  )
}

View.loader = async ({
  params,
}: LoaderFunctionArgs): Promise<ViewParams | Response> => {
  if (!params.code) {
    return { instances: [], files: [] }
  }

  const result = await shareService.instances(params.code)

  const files = await shareService.filesByCode(params.code)

  if (result instanceof ApiError) {
    return handleApiError(result)
  }
  if (files instanceof ApiError) {
    return handleApiError(files)
  }

  return { instances: result, files }
}

export default View
