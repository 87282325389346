import * as cornerstone from "@cornerstonejs/core"
import { addInstance, get } from "./ptScalingMetaDataProvider"
import hardcodedMetaDataProvider from "../webLoader/hardcodedMetaDataProvider"

const { calibratedPixelSpacingMetadataProvider } = cornerstone.utilities

export default function initProviders() {
  cornerstone.metaData.addProvider(get.bind({ addInstance, get }), 10000)
  cornerstone.metaData.addProvider(
    (type, imageId) => hardcodedMetaDataProvider(type, imageId, []),
    10000
  )
  cornerstone.metaData.addProvider(
    calibratedPixelSpacingMetadataProvider.get.bind(
      calibratedPixelSpacingMetadataProvider
    ),
    11000
  )
}
