import React from "react"
import { ImageType, ImageFile, Instance, PdfFile, VideoFile } from "./types"
import { Displayable } from "../../services/ShareService/types"
import Cornerstone from "./Cornerstone"
import Image from "./components/Image"
import PdfRenderer from "./components/PdfRenderer"
import Video from "./components/Video"

interface ViewerProps {
  object?: Displayable
  canvasRef: React.Ref<HTMLCanvasElement>
}

export const Viewer = ({ object, canvasRef }: ViewerProps) => {
  if (object?.contentType === "application/pdf") {
    const pdfFile = object as PdfFile
    return <PdfRenderer id={pdfFile.id} link={pdfFile.link} />
  }
  if (
    object?.contentType &&
    Object.values(ImageType).includes(object.contentType as ImageType)
  ) {
    const imageFile = object as ImageFile
    return <Image id={imageFile.id} link={imageFile.link} />
  }
  if (object?.contentType === "video/mp4") {
    const videoFile = object as VideoFile
    return <Video id={videoFile.id} link={videoFile.link} />
  }
  if (object?.contentType !== "dicom") {
    return <></>
  }
  const instance = object as Instance
  if (instance && instance.frames === 1) {
    return <Cornerstone instance={instance} />
  }
  return (
    <canvas
      ref={canvasRef}
      style={{ position: "relative", width: "100%", height: "100%" }}
    />
  )
}
