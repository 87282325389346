import { LoaderFunctionArgs } from "@remix-run/router/dist/utils"
import React from "react"
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormText,
  Row,
} from "react-bootstrap"
import { Form as ReactForm, redirect, useLoaderData } from "react-router-dom"
import ShareForm from "../components/ShareForm"
import {
  ApiError,
  handleApiError,
  handleNotFoundError,
} from "../services/ApiService"
import shareService from "../services/ShareService"
import { Share, ShareUpdate } from "../services/ShareService/types"
import { convertDateFormat, formatShareURL } from "../utils"
import { pathList } from "../routes/pathList"

const Edit = () => {
  const share = useLoaderData() as Share
  const url = formatShareURL(share.code)

  return (
    <ReactForm method="post">
      <ShareForm share={share} />
      <Container>
        <FormGroup>
          <FormText>Created: {share?.created.toLocaleString()}</FormText>
        </FormGroup>
        <FormGroup>
          <FormText>
            Share link: <a href={url}>{url}</a>
          </FormText>
        </FormGroup>
        <Row className="justify-content-md-center">
          <Col md="1">
            <FormGroup>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </ReactForm>
  )
}

Edit.loader = async ({ params }: LoaderFunctionArgs) => {
  if (!params.id) {
    return
  }
  const response = await shareService.get(params.id)
  if (!response) {
    return handleNotFoundError()
  }
  if (response instanceof ApiError) {
    return handleApiError(response)
  }
  return response
}

Edit.action = async ({ request, params }: LoaderFunctionArgs) => {
  if (!params.id) {
    return
  }
  const formData = await request.formData()
  const updates = Object.fromEntries(formData) as unknown as ShareUpdate
  const data = { ...updates, dob: convertDateFormat(updates.dob) }
  const response = await shareService.update(params.id, data)
  if (response instanceof ApiError) {
    return handleApiError(response)
  }
  return redirect(pathList.list.reverse())
}

export default Edit
