import c from "classnames"
import React from "react"
import { Link, Outlet, useNavigation } from "react-router-dom"
import User from "../components/user"
import logo from "../logo.png"
import { pathList } from "../routes/pathList"
import { useMatchingPath } from "../hooks"
import s from "./root.module.scss"

export default function Root() {
  const navigation = useNavigation()
  const hideLoginLink = useMatchingPath(["login", "lookupDetails"])

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.logo}>
          <Link to={pathList.root.reverse()}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className={s.title}>
          MedIQ Patient Information Management System
        </div>
        {!hideLoginLink && (
          <div className={s.user}>
            <User />
          </div>
        )}
      </div>
      <div
        className={c(s.content, {
          loading: navigation.state === "loading",
        })}
      >
        <Outlet />
      </div>
    </div>
  )
}
